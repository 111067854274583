import React from 'react'
import '../styles/NewOrders.css'
import { MdOutlineArrowRightAlt } from "react-icons/md";
import banana from "../assets/banana_01.png";
import blueberry from "../assets/blueberry_01.jpeg";
import mango from "../assets/mango_01.jpeg";
import { GoArrowUpRight } from "react-icons/go";

const orders = [
    {
        id: "2421",
        products: [
            {
                name: 'Banana',
                image01: banana
            },
            {
                name: 'Blueberry',
                image01: blueberry
            },
            {
                name: 'Mango',
                image01: mango
            },
        ],
        price: 32.5
    },
    {
        id: "2420",
        products: [
            {
                name: 'Banana',
                image01: banana
            },
            {
                name: 'Blueberry',
                image01: blueberry
            },
            {
                name: 'Mango',
                image01: mango
            },
        ],
        price: 32.5
    },
    {
        id: "2419",
        products: [
            {
                name: 'Banana',
                image01: banana
            },
            {
                name: 'Blueberry',
                image01: blueberry
            },
            {
                name: 'Mango',
                image01: mango
            },
        ],
        price: 32.5
    },
    {
        id: "2418",
        products: [
            {
                name: 'Banana',
                image01: banana
            },
            {
                name: 'Blueberry',
                image01: blueberry
            },
            {
                name: 'Mango',
                image01: mango
            },
        ],
        price: 32.5
    },
    {
        id: "2417",
        products: [
            {
                name: 'Banana',
                image01: banana
            },
            {
                name: 'Blueberry',
                image01: blueberry
            },
            {
                name: 'Mango',
                image01: mango
            },
        ],
        price: 32.5
    },
]

export default function NewOrders() {
    return (
        <div className='new__orders'>
            <div className='d-flex justify-content-between'>
                <h5>New Orders</h5>
                <MdOutlineArrowRightAlt className='more' size={28} />
            </div>

            <div>
                <div className='header'>
                    <span>Order #</span>
                    <div className='items__price__header__grid'>
                        <span>Items</span>
                        <span className='price__header'>Price</span>
                    </div>
                    <span> </span>
                </div>
                <hr />
                {
                    orders.map(item => <NewOrdersRow item={item} key={item.id} />)
                }
            </div>
        </div>
    )
}

const NewOrdersRow = (props) => {
    const { id, products, price } = props.item

    return (
        <div>
            <div className='new__order__row'>
                <span>{id}</span>
                <div className='items__price__grid'>
                    <div className='d-flex gap-3 '>
                        {
                            products.map(product => <img src={product.image01} alt='' />)
                        }
                        <span className='dot'>...</span>
                        <span className='items__count'>{products.length} items</span>
                    </div>
                    <span>{price}</span>
                </div>
                <div className='more'>
                    <GoArrowUpRight size={20} />
                </div>
            </div>
            <hr />
        </div>
    )
}