import React, { useState } from 'react'
import '../styles/AddProduct.css'
import upload_area from '../assets/upload_area.png'
import { MdDeleteOutline } from "react-icons/md";

export default function AddProduct({setIsAddProduct}) {
    const [image1, setImage1] = useState(false)
    const [image2, setImage2] = useState(false)

    const [isAddCategory, setIsAddCategory] = useState(false)

    const handleCategoryValue = (e) => {
        if (e.target.value === 'Add Category') {
            setIsAddCategory(true)
        } else {
            setIsAddCategory(false)
        }
    };

    return (
        <div className='add__product'>
            <form className='d-flex flex-column gap-4 add__product__form'>
                <div className='d-flex flex-column gap-2 img__upload'>
                    <div className='d-flex flex-column'>
                        <span>Upload Image</span>
                        <span className='maximum__images__note'>Maximum up to 2 images</span>
                    </div>
                    <div className='d-flex gap-3'>
                        {image1 &&
                            <div className='image__area'>
                                <img src={URL.createObjectURL(image1)} alt='' />
                                <div className='action__buttons'>
                                    <MdDeleteOutline
                                        className='delete__icon'
                                        onClick={() => setImage1(false)} />
                                </div>
                            </div>
                        }
                        {image2 &&
                            <div className='image__area'>
                                <img src={URL.createObjectURL(image2)} alt='' />
                                <div className='action__buttons'>
                                    <MdDeleteOutline
                                        className='delete__icon'
                                        onClick={() => setImage2(false)} />
                                </div>
                            </div>
                        }
                        {((!image1 && !image2) || (image1 && !image2)) &&
                            <div className='upload__area'>
                                <label htmlFor='image'>
                                    <img src={upload_area} alt='' />
                                </label>
                                <input onChange={(e) => { !image1 ? setImage1(e.target.files[0]) : setImage2(e.target.files[0]) }} type='file' id='image' hidden required />
                            </div>
                        }
                    </div>
                </div>
                <div className='d-flex flex-column gap-2 product__name'>
                    <span>Product name</span>
                    <input type='text' id='name' placeholder='Product name' />
                </div>
                <div className='d-flex flex-column gap-2 product__description'>
                    <span>Product description</span>
                    <textarea name='description' rows='6' placeholder='Write product description here' />
                </div>
                <div className='d-flex flex-column gap-2 category'>
                    <span>Product category</span>
                    <div className='d-flex gap-3'>
                        <select name='category'
                            onChange={handleCategoryValue}>
                            <option value='Default' disabled>Select Category</option>
                            <option value='Fruits & Veges'>Fruits & Veges</option>
                            <option value='Cooking Oil & Ghee'>Cooking Oil & Ghee</option>
                            <option value='Meat & Fish'>Meat & Fish</option>
                            <option value='Bakery & Snacks'>Bakery & Snacks</option>
                            <option value='Dairy & Eggs'>Dairy & Eggs</option>
                            <option value='Beverages'>Beverages</option>
                            <option value='Add Category'>Add Category</option>
                        </select>
                        {
                            isAddCategory ?
                                <div className='d-flex align-items-center justify-content-center gap-3'>
                                    <input type='text' placeholder='New Category'></input>
                                    <button className='add__category__btn'>Add</button>
                                </div>
                                : <></>
                        }
                    </div>

                </div>
                <div className='d-flex flex-column gap-2 price'>
                    <span>Product price</span>
                    <input type='number' name='price' placeholder='$0' />
                </div>
                <div className='d-flex gap-5'>
                    <button type='submit' className='add__btn'>Add</button>
                    <button type='cancel' className='cancel__btn' onClick={()=>setIsAddProduct(false)}>Cancel</button>
                </div>
            </form>
        </div>
    )
}