import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Stack,Typography } from '@mui/material';

const CustomTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: 'rgba(83, 177, 117, 1.0)', // #53B175 with 15% opacity
    color: '#FFFFFF', // Change text color if needed
    borderRadius: '8px',
  },
  '&.MuiTab-root': {
    textTransform: 'none',
  
   
  },
}));

const CustomTabs = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    display: 'none', // Removes the default underline
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, mt:"20px",border:"1px solid #A9A9A9",borderRadius:"8px" }}>{children}</Box>}
    </div>
  );
}



CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function OrdersTabs({data,setOrderCycle}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setOrderCycle("new")
    }
    if (newValue === 1) {
      setOrderCycle("preparing")
    } 
    if (newValue === 2) {
      setOrderCycle("delivery")
    } 
  };

  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: 'divider',backgroundColor:"#DADADA",borderRadius:"8px",textAlign:"center" }}>
        <CustomTabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth" centered>
          <CustomTab label="New" {...a11yProps(0)} />
          <CustomTab label="Preparing" {...a11yProps(1)} />
          <CustomTab label="Delivery" {...a11yProps(2)} />
        </CustomTabs>
      </Box>

      <CustomTabPanel value={value} index={0} padding={0}>
        <Box padding={2}>
          <Stack direction="row" display={"flex"} justifyContent={"space-between"}>
            <Stack alignItems="flex-start">
              <Typography>Order #004</Typography>
              <Typography fontSize='0.9rem'>16 May, 2024 2:51 PM</Typography>
            </Stack >
              <Box display="flex"
                    justifyContent="center"
                    alignItems="center" >
                <Typography align='right'>$6.92</Typography>
              </Box>
          </Stack>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={0} padding={0}>
        <Box padding={2}>
          <Stack direction="row" display={"flex"} justifyContent={"space-between"}>
            <Stack alignItems="flex-start">
              <Typography>Order #003</Typography>
              <Typography fontSize='0.9rem'>16 May, 2024 2:51 PM</Typography>
            </Stack >
              <Box display="flex"
                    justifyContent="center"
                    alignItems="center" >
                <Typography align='right'>$6.92</Typography>
              </Box>
          </Stack>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={0} padding={0}>
        <Box padding={2}>
          <Stack direction="row" display={"flex"} justifyContent={"space-between"}>
            <Stack alignItems="flex-start">
              <Typography>Order #002</Typography>
              <Typography fontSize='0.9rem'>16 May, 2024 2:51 PM</Typography>
            </Stack >
              <Box display="flex"
                    justifyContent="center"
                    alignItems="center" >
                <Typography align='right'>$6.92</Typography>
              </Box>
          </Stack>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={0} padding={0}>
        <Box padding={2}>
          <Stack direction="row" display={"flex"} justifyContent={"space-between"}>
            <Stack alignItems="flex-start">
              <Typography>Order #001</Typography>
              <Typography fontSize='0.9rem'>16 May, 2024 2:51 PM</Typography>
            </Stack >
              <Box display="flex"
                    justifyContent="center"
                    alignItems="center" >
                <Typography align='right'>$6.92</Typography>
              </Box>
          </Stack>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} padding={0}>
      <Box padding={2}>
          <Stack direction="row" display={"flex"} justifyContent={"space-between"}>
            <Stack alignItems="flex-start">
              <Typography>Order #001</Typography>
              <Typography fontSize='0.9rem'>16 May, 2024 2:51 PM</Typography>
            </Stack >
              <Box display="flex"
                    justifyContent="center"
                    alignItems="center" >
                <Typography align='right'>$6.92</Typography>
              </Box>
          </Stack>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <Box padding={2}>
          <Stack direction="row" display={"flex"} justifyContent={"space-between"}>
            <Stack alignItems="flex-start">
              <Typography>Order #001</Typography>
              <Typography fontSize='0.9rem'>16 May, 2024 2:51 PM</Typography>
            </Stack >
              <Box display="flex"
                    justifyContent="center"
                    alignItems="center" >
                <Typography align='right'>$6.92</Typography>
              </Box>
          </Stack>
        </Box>
      </CustomTabPanel>
    </>
  );
}

// https://www.freecodecamp.org/news/build-a-high-performance-tab-component/