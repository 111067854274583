import React from 'react'
import '../styles/OrderAnalytics.css'
import { MdDoneAll, MdOutlineDeliveryDining } from "react-icons/md";
import { TbClockCancel, TbClockQuestion } from "react-icons/tb";

export default function OrderAnalytics() {
    return (
        <div className='order__analytics'>
            <div className='data__column'>
                <div className='data__row'>
                    <MdDoneAll className='icon' />
                    <div className='d-flex flex-column'>
                        <span className='title'>Total Order Complete</span>
                        <span className='data'>15</span>
                    </div>
                </div>

                <div className='data__row'>
                    <MdOutlineDeliveryDining className='icon' />
                    <div className='d-flex flex-column'>
                        <span className='title'>Total Order Delivered</span>
                        <span className='data'>17</span>
                    </div>

                </div>
            </div>

            <div className='data__column'>
                <div className='data__row'>
                    <TbClockCancel className='icon' />
                    <div className='d-flex flex-column'>
                        <span className='title'>Total Order Rejected</span>
                        <span className='data'>3</span>
                    </div>
                </div>

                <div className='data__row'>
                    <TbClockQuestion className='icon' />
                    <div className='d-flex flex-column'>
                        <span className='title'>Order Pending</span>
                        <span className='data'>2</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
