import './App.css';
import React from 'react'
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Routes from '../src/routes/Routers'
import { Container } from 'reactstrap';

function App() {
  return (
    <>
      <Container className='app d-flex pt-3 gap-5'>
        <Sidebar />
        <div className='app-content'>
          <Navbar />
          <Routes />
        </div>
      </Container>
    </>
  )
}

export default App;
