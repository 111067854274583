import React from 'react'
import '../styles/Dashboard.css'
import { Col, Row } from 'reactstrap'
import ProgressAnalytics from '../components/ProgressAnalytics';
import OrderAnalytics from '../components/OrderAnalytics';
import PopularFood from '../components/PopularFood';
import NewOrders from '../components/NewOrders';

export default function Dashboard() {
  return (
    <div className='dashboard'>
      <Row>
        <Col lg='8' md='12' sm='12'>
          <OrderAnalytics />
          <NewOrders />
        </Col>

        <Col lg='4' md='12' sm='12'>
            <ProgressAnalytics />
            <PopularFood />
        </Col>
      </Row>
    </div>
  )
}
