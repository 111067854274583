import React from 'react'
import '../styles/LiveOrders.css'
import { MdAssignment, MdPendingActions, MdLocalShipping } from "react-icons/md";

export default function LiveOrders() {
  return (
    <div className='live__orders'>
      <div className='d-flex flex-column gap-3'>
        <h5>Live Orders</h5>

        <div className='live__orders__container'>
          <div className='total__orders'>
            <div className='orders__background' style={{ backgroundColor: "#EDCA6E" }}>
              <MdAssignment />
            </div>
            <div className='d-flex flex-column'>
              <span className='number__of__orders'>47</span>
              <span className='title'>Orders</span>
            </div>
          </div>

          <div className='total__pending'>
            <div className='orders__background' style={{ backgroundColor: "#BACDED" }}>
              <MdPendingActions />
            </div>
            <div className='d-flex flex-column'>
              <span className='number__of__orders'>56</span>
              <span className='title'>Total Pending</span>
            </div>
          </div>

          <div className='total__dispatch'>
            <div className='orders__background' style={{ backgroundColor: "#B8E3E0" }}>
              <MdLocalShipping />
            </div>
            <div className='d-flex flex-column'>
              <span className='number__of__orders'>26</span>
              <span className='title'>Total Dispatch</span>
            </div>
          </div>
        </div>

        <div>
          <h6>Orders</h6>
        </div>
      </div>
    </div>
  )
}