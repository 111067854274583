import React from 'react'
import '../styles/PopularFood.css'
import { PieChart } from '@mui/x-charts/PieChart';

export default function PopularFood() {
    return (
        <div className='d-flex flex-column g-3 popular__food'>
            <span>Popular Food</span>

            <PieChart
                colors={['#F0BA00', '#DA4E4A', '#8DC72D']}
                series={[
                    {
                        data: [
                            { id: 0, value: 10 },
                            { id: 1, value: 15 },
                            { id: 2, value: 20 },
                        ],
                        innerRadius: 30,
                    },
                ]}
                width={250}
                height={200}
            />

            <div>
                <div className='d-flex align-items-center gap-3'>
                    <div className='color__indicator' style={{ backgroundColor: "#F0BA00" }}></div>
                    <span>Product A</span>
                </div>
                <div className='d-flex align-items-center gap-3'>
                    <div className='color__indicator' style={{ backgroundColor: "#DA4E4A" }}></div>
                    <span>Product B</span>
                </div>
                <div className='d-flex align-items-center gap-3'>
                    <div className='color__indicator' style={{ backgroundColor: "#8DC72D" }}></div>
                    <span>Product C</span>
                </div>
            </div>
        </div>
    )
}
