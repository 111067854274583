import React from 'react'
import '../styles/LiveOrders.css'
// import OrdersTabs from './OrdersTabs'
import Box from '@mui/material/Box';
import { Typography,Divider,Stack } from '@mui/material';
import product_10_image_01 from "../assets/apple_01.jpeg";
import product_10_image_02 from "../assets/orange_01.jpeg";
import Button from '@mui/material/Button';


// const data = [
//   {
//     label: 'Your details',
//     description: `Please provide your name and email`,
//   },
//   {
//     label: 'Company details',
//     description:
//       'A few details about your company',
//   },
//   {
//     label: 'Invite your team',
//     description: `Start collaborating with your team`,
//   },
// ];


export default function OrdersDetailNew() {

  return (
    <>
      <Typography align='left'>Order Details</Typography>
      <Box sx={{p: "25px", mt:"20px",mb:"20px",border:"1px solid #A9A9A9",borderRadius:"8px"}}>
        <Typography align='left'>Order #004</Typography>
        <Typography align='left' fontSize='0.9rem'>16 May, 2024 2:51PM</Typography>
        <Divider sx={{border:"1px solid #A9A9A9", mt:"17px",mb:"17px"}}/>
        <Stack sx={{mb:"17px"}}>
            <Typography align='left' fontSize='0.9rem' color='#696969'>Preparing Time</Typography>
            <Typography align='left'>00h:25m:30s</Typography>
          </Stack>
        <Stack direction="row" display="flex" justifyContent="space-between" >
          <Stack>
            <Typography align='left' fontSize='0.9rem' color='#696969'>Delivery Address</Typography>
            <Typography align='left'>Unit 123, Yonge st, Toronto, ON</Typography>
          </Stack>
          <Stack>
            <Typography align='left'>Austin Paul</Typography>
            <Typography align='left'>+123 456 7890</Typography>
          </Stack>
        </Stack>
        <Divider sx={{border:"1px solid #A9A9A9",mt:"17px",mb:"17px"}}/>
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <img width="60px" height="60px"src={product_10_image_01} alt="" />
            <Stack display="flex" justifyContent="center" alignItems="center">
              <Typography align='left'>Apple</Typography>
              <Typography align='left'>3ea</Typography>
            </Stack>
          </Stack>
          <Stack display="flex" justifyContent="center" alignItems="center">
           <Typography >$4.47</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <img width="60px" height="60px"src={product_10_image_02} alt="" />
            <Stack display="flex" justifyContent="center" alignItems="center">
              <Typography align='left'>Apple</Typography>
              <Typography align='left'>3ea</Typography>
            </Stack>
          </Stack>
          <Stack display="flex" justifyContent="center" alignItems="center">
           <Typography >$4.47</Typography>
          </Stack>
        </Stack>
        <Divider sx={{border:"1px solid #A9A9A9", mt:"17px",mb:"17px"}}/>
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Typography>Total</Typography>
          <Typography>$6.92</Typography>
        </Stack>
      </Box>
      <Stack direction="row" display="flex" justifyContent="flex-end" spacing={2}>
        
          <Button variant="contained" 
                  sx={{
                    backgroundColor: '#53B175', // Background color
                    '&:hover': {
                      backgroundColor: 'darkgreen', // Darkened green on hover
          },
        }}>Ready</Button>
      </Stack>
    </>
  )
}
