import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import LiveOrders from '../pages/LiveOrders'
import OrderHistory from '../pages/OrderHistory'
import Products from '../pages/Products'
import Orders from '../pages/Orders'
import OrderDetail from '../pages/OrderDetail'

export default function Routers() {
    return (
      <Routes>
          <Route path='/' element={<Navigate to='/dashboard/'/>} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/live-orders' element={<LiveOrders />} />
          <Route path='/orderhistory' element={<OrderHistory />} />
          <Route path='/products' element={<Products />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/orders/:id' element={<OrderDetail />} />
      </Routes>
    )
  }