import React, { useState } from 'react'
import '../styles/ProductList.css'
import { RiSearchLine } from "react-icons/ri";
import { MdExpandMore, MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import { Col, Row } from 'reactstrap';
import products from '../data/products'
import Switch from "react-switch";

export default function ProductList() {
    const [searchTerm, setSearchTerm] = useState("")

    return (
        <div className='d-flex flex-column gap-3 product__list'>
            <Row>
                <Col lg='6' md='6' sm='6' xs='12' className='mb-3'>
                    <div className="sorting__widget">
                        <select>
                            <option>Default</option>
                            <option value='ascending'>Alphabetically, A-Z</option>
                            <option value='descending'>Alphabetically, Z-A</option>
                            <option value='high-price'>High Price</option>
                            <option value='low-price'>Low Price</option>
                        </select>

                        <MdExpandMore />
                    </div>
                </Col>

                <Col lg='6' md='6' sm='6' xs='12'>
                    <div className='search__widget'>
                        <input
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            type='text'
                            placeholder='Search for product' />
                        <RiSearchLine />
                    </div>
                </Col>
            </Row>

            <div>
                <div className='list__content'>
                    <span>#</span>
                    <span className='image'>Image</span>
                    <span>Name</span>
                    <span className='in_stock'>In Stock</span>
                    <span className='action'>Action</span>
                </div>
                <hr />
                {
                    products.map(item => <ProductRow item={item} key={item.id} />)
                }
            </div>

        </div>
    )
}

const ProductRow = (props) => {
    const { id, image01, name, category } = props.item

    const [inStock, setInStock] = useState(true)

    return (
        <div>
            <div className='product__items__title product__items__item'>
                <span>{id}</span>
                <img src={image01} alt="" />
                <div className='product'>
                    <span>{name}</span>
                    <span className='category'>{category}</span>
                </div>
                <div className='in__stock__switch'>
                    <Switch onChange={() => setInStock(!inStock)} checked={inStock} />
                </div>
                <div className='d-flex gap-3 action'>
                    <MdOutlineEdit className='action__icon' />
                    <MdDeleteOutline className='action__icon' />
                </div>
            </div>
            <hr />
        </div>
    )
}