import React from 'react'
import '../styles/LiveOrders.css'
import orders from '../data/orders'

const divClassName=`px-3 py-2 bg-white border border-slate-400 rounded`;

function ItemsSummary() {
  return(
    <div className={divClassName}>
      <table className='w-full'>
        <tr className='text-lg font-bold'>
          <th className='py-1 '>Item Summary</th>
          <th>Qty</th>
          <th>Price</th>
          <th>Total Price</th>
        </tr>
        {orders[0].products.map(product =>
          <tr className='border-t border-t-slate-300'>
            <td className='flex items-center gap-1 py-1'><img className='h-10' src={product.image01} alt="" />{product.name}</td>
            <td>x{product.quantity}</td>
            <td>{product.price}</td>
            <td>{product.price*product.quantity}</td>
          </tr>
        )}        
      </table>
    </div>
  )
}

function CustomerAndOrderDetails() {
  return(
    <div className={`mt-3 ${divClassName}`}>
      <table className='w-full'>
        <tr>
          <td className='py-1 text-lg font-bold' colSpan={2}>Customer And Order Details</td>
        </tr>
        <tr className='border-t border-t-slate-300'>
          <td className='flex items-center gap-1 py-1'>Customer Name</td>
          <td>{orders[0].name}</td>
        </tr>
        <tr className='border-t border-t-slate-300'>
          <td className='flex items-center gap-1 py-1'>Phone Number</td>
          <td>{orders[0].phone}</td>
        </tr>
        <tr className='border-t border-t-slate-300'>
          <td className='flex items-center gap-1 py-1'>Payment Method</td>
          <td>{orders[0].payment}</td>
        </tr>
      </table>
    </div>
  )
}

function RiderDetails() {
  return(
    <div className={divClassName}>
      <div className='text-lg font-bold'>Rider Details</div>
      <div className='flex gap-2 items-center mt-2'>
        <div className='w-10 h-10 border rounded'></div> 
        Rider Name
        <div className='px-2 py-2 bg-emerald-600 rounded text-white'>Track Rider</div>
      </div>
    </div>
  )
}

function OrderSummary(){
  return(
    <div className={`mt-3 ${divClassName}`}>
      <table className='w-full'>
        <tr>
          <td className='py-1 text-lg font-bold' colSpan={2}>Order Summary</td>
        </tr>
        <tr>
          <td className='flex items-center gap-1 py-1 font-bold'>Order Created</td>
          <td className='text-right'>{orders[0].createdDate}</td>
        </tr>
        <tr>
          <td className='flex items-center gap-1 py-1 font-bold'>Order Time</td>
          <td className='text-right'>{orders[0].orderTime}</td>
        </tr>
        <tr>
          <td className='flex items-center gap-1 py-1 font-bold'>Subtotal</td>
          <td className='text-right'>{orders[0].total}</td>
        </tr>
        <tr>
          <td className='flex items-center gap-1 py-1 font-bold'>Delivery Fee</td>
          <td className='text-right'>{orders[0].deliveryFee}</td>
        </tr>
      </table>
    </div>
  )
}

function Total(){
  return(
    <div className={`mt-3 ${divClassName}`}>
      <table className='w-full'>
        <tr>
          <td className='flex items-center gap-1 py-1 font-bold'>Total</td>
          <td className='text-right'>{orders[0].total+orders[0].deliveryFee}</td>
        </tr>
      </table>
    </div>
  )
}

function DeliveryAddress(){
  return(
    <div className={`mt-3 ${divClassName}`}>
      <div className='block'>
        <div className='text-lg font-bold'>Delivery Address</div>
        <div><b>Address Line:</b> {orders[0].address.addressLine}</div>
        <div><b>Flat / Building Name:</b> {orders[0].address.flat}</div>
        <div><b>Street Name:</b> {orders[0].address.stress}</div>
        <div><b>Postalcode:</b> {orders[0].address.postalCode}</div>
      </div>
    </div>
  )
}

export default function OrderDetail() {
    return (
      <div className='live__orders'>
        <div className='flex gap-2 items-center'>
          Order Number <span className='text-red-500'>#{orders[0].id}</span>
          <div className='px-2 py-2 bg-slate-700 rounded text-white'>Message Customer</div>
          <div className='px-2 py-2 bg-emerald-600 rounded text-white'>{orders[0].status}</div>
        </div>
        <div className='flex gap-2 mt-3 w-full'>
          <div className='block w-[70%]'>
            <ItemsSummary/>
            <CustomerAndOrderDetails/>
          </div>
          <div className='ml-3 block w-[30%]'>
            <RiderDetails/>
            <OrderSummary/>
            <Total/>
            <DeliveryAddress/>
          </div>
        </div>
      </div>
    )
  }