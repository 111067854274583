import React from 'react'
import '../styles/ProgressAnalytics.css'

import { SparkLineChart } from '@mui/x-charts';

export default function ProgressAnalytics() {
    return (
        <div className='progress__analytics'>
            <span className='status'>Nice Progress Today!</span>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column'>
                    <span className='rate'>+25%</span>
                    <span className='number'>56</span>
                </div>

                <SparkLineChart
                    plotType="bar"
                    data={[1, 4, 2, 5, 7, 2, 4, 6]}
                    height={80}
                />
            </div>

            <div className='d-flex gap-3 pt-3'>
                <button>Week</button>
                <button>Month</button>
                <button>Year</button>
            </div>
        </div>
    )
}
