import React from 'react'
import '../styles/Navbar.css'
import { BiUser } from 'react-icons/bi';

export default function Navbar() {
    return (
        <div className='navbar'>
            <span></span>
            <div className='profile'>
                <BiUser />
            </div>
        </div>
    )
}
