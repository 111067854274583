import React, { useState } from 'react'
import '../styles/Products.css'
import ProductList from '../components/ProductList';
import AddProduct from '../components/AddProduct';

export default function Products() {
  const [isAddProduct, setIsAddProduct] = useState(false)

  return (
    <div className='products'>
      {isAddProduct ?
        <div className='d-flex flex-column gap-3'>
          <h5>Add Product</h5>
          <AddProduct setIsAddProduct={setIsAddProduct}/>
        </div>
        : <div className='d-flex flex-column gap-3'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5>Products</h5>
            <button
              className='addProduct__btn'
              onClick={()=>setIsAddProduct(true)}>
              Add Product
            </button>
          </div>

          <ProductList />
        </div>
      }
    </div>
  )
}