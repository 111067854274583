import product_07_image_01 from "../assets/orange_01.jpeg";
import product_10_image_01 from "../assets/apple_01.jpeg";

const orders = [
    {
      id: "2632",
      name: "Brooklyn Zoe",
      phone: "1234567890",
      payment: "Cash",
      status: "Delivered",
      total: 6.92,
      deliveryFee: 30.0,
      address: {
        addressLine: "14 Anglesey Rood",
        flat: "James Court",
        stress: "Anglesey Road",
        postalCode: "M2N"
      },
      note: "N/A",
      createdDate: "Sun, May 7, 2021",
      orderTime: "06:24 AM",
      products: [
        {
            name: "apple",
            price: 1.49,
            quantity: 3,
            image01: product_10_image_01
        },
        {
            name: "orange",
            price: 0.49,
            quantity: 5,
            image01: product_07_image_01
        }
      ]
    },
    {
        id: "2633",
        name: "Alice Krejcava",
        phone: "1234567890",
        payment: "Paid",
        status: "Collected",
        total: 6.92,
        deliveryFee: 30.0,
        address: {
          addressLine: "14 Anglesey Rood",
          flat: "James Court",
          stress: "Anglesey Road",
          postalCode: "M2N"
        },
        note: "N/A",
        createdDate: "Sun, May 7, 2021",
        orderTime: "06:24 AM",
        products: [
          {
              name: "apple",
              price: 1.49,
              quantity: 3,
              image01: product_10_image_01
          }
        ]
      },
      {
        id: "2634",
        name: "Jurriaan van",
        phone: "1234567890",
        payment: "Card",
        status: "Completed",
        total: 6.92,
        deliveryFee: 30.0,
        address: {
          addressLine: "14 Anglesey Rood",
          flat: "James Court",
          stress: "Anglesey Road",
          postalCode: "M2N"
        },
        note: "N/A",
        createdDate: "Sun, May 7, 2021",
        orderTime: "06:24 AM",
        products: [
          {
              name: "apple",
              price: 1.49,
              quantity: 3,
              image01: product_10_image_01
          }
        ]
      },
      {
        id: "2635",
        name: "Shammikh AI",
        phone: "1234567890",
        payment: "Card",
        status: "Cancelled",
        total: 6.92,
        deliveryFee: 30.0,
        address: {
          addressLine: "14 Anglesey Rood",
          flat: "James Court",
          stress: "Anglesey Road",
          postalCode: "M2N"
        },
        note: "N/A",
        createdDate: "Sun, May 7, 2021",
        orderTime: "06:24 AM",
        products: [
          {
              name: "apple",
              price: 1.49,
              quantity: 3,
              image01: product_10_image_01
          }
        ]
      },
  ];
  
  export default orders;