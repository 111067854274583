import React, { useState } from 'react'
import '../styles/LiveOrders.css'
import OrdersTabs from './OrdersTabs'
import Box from '@mui/material/Box';
// import OrdersDetail from './OrdersDetailNew';
import { Stack } from '@mui/material';
import OrdersDetailNew from './OrdersDetailNew';
import OrderDetailsPreparing from './OrderDetailsPreparing'
import OrderDetailsDelivery from './OrderDetailsDelivery'


const data = [
  {
    label: 'Your details',
    description: `Please provide your name and email`,
  },
  {
    label: 'Company details',
    description:
      'A few details about your company',
  },
  {
    label: 'Invite your team',
    description: `Start collaborating with your team`,
  },
];


export default function Orders() {
  const [orderCycle,setOrderCycle] = useState("")
  return (
    <div className='live__orders'>
      <Stack direction="row" spacing={2}>
        <Box sx={{width:'40%',backgroundColor:'#FFFFFF',textAlign:"center",padding:"10px", borderRadius:'16px'}}>
          <OrdersTabs data={data} setOrderCycle={setOrderCycle}/>
        </Box>
        <Box sx={{width:'60%',backgroundColor:'#FFFFFF',textAlign:"center",padding:"30px", borderRadius:'16px'}}>
          {orderCycle==="new" && <OrdersDetailNew/>}
          {orderCycle==="preparing" && <OrderDetailsPreparing/> }
          {orderCycle==="delivery" && <OrderDetailsDelivery/> }
         
        </Box>
      </Stack>
     
      
    </div>
  )
}
