import React from 'react'
import '../styles/Sidebar.css'
import { RiDashboardLine } from "react-icons/ri";
import { LuConciergeBell } from "react-icons/lu";
import { TbClockCheck } from "react-icons/tb";
import { BiFoodMenu } from "react-icons/bi";
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png'
import { PiBicycle } from "react-icons/pi";


export default function Sidebar() {
    return (
        <div className='sidebar'>
            <div className='d-flex flex-column align-items-center'>
                <img className='logo' src={logo} alt='' />
                <span>Admin panel</span>
            </div>
            <div className='sidebar__options'>
                <NavLink to='/dashboard' className='sidebar__option'>
                    <RiDashboardLine />
                    <span>Dashboard</span>
                </NavLink>
                <NavLink to='/live-orders' className='sidebar__option'>
                    <LuConciergeBell />
                    <span>Live Orders</span>
                </NavLink>
                <NavLink to='/orderhistory' className='sidebar__option'>
                    <TbClockCheck />
                    <span>Order History</span>
                </NavLink>
                <NavLink to='/products' className='sidebar__option'>
                    <BiFoodMenu />
                    <span>Products</span>
                </NavLink>
                <NavLink to='/orders' className='sidebar__option'>
                    <PiBicycle />
                    <span>Orders</span>
                </NavLink>
            </div>
        </div>
    )
}
