import product_01_image_01 from "../assets/red-pepper_01.png";
import product_01_image_02 from "../assets/red-pepper_02.jpeg";
import product_02_image_01 from "../assets/egg-noodles_01.png";
import product_02_image_02 from "../assets/egg-noodles_02.jpeg";
import product_03_image_01 from "../assets/egg-pasta_01.png";
import product_03_image_02 from "../assets/egg-pasta_02.png";
import product_04_image_01 from "../assets/egg_01.png";
import product_04_image_02 from "../assets/egg_02.jpeg";
import product_05_image_01 from "../assets/mango_01.jpeg";
import product_05_image_02 from "../assets/mango_02.jpeg";
import product_06_image_01 from "../assets/mayonnaise_01.png";
import product_06_image_02 from "../assets/mayonnaise_02.png";
import product_07_image_01 from "../assets/orange_01.jpeg";
import product_07_image_02 from "../assets/orange_02.png";
import product_08_image_01 from "../assets/watermelon_01.jpeg";
import product_08_image_02 from "../assets/watermelon_02.jpeg";
import product_09_image_01 from "../assets/white-carrot_01.jpg";
import product_09_image_02 from "../assets/white-carrot_02.jpeg";
import product_10_image_01 from "../assets/apple_01.jpeg";
import product_10_image_02 from "../assets/apple_02.png";
import product_11_image_01 from "../assets/banana_01.png";
import product_11_image_02 from "../assets/banana_02.jpeg";
import product_12_image_01 from "../assets/blueberry_01.jpeg";
import product_12_image_02 from "../assets/blueberry_02.jpeg";

const products = [
  {
    id: "01",
    name: "Red Pepper",
    price: 6.49,
    image01: product_01_image_01,
    image02: product_01_image_02,
    category: "Vegetables",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque. ",
  },
  {
    id: "02",
    name: "Egg Noodles",
    price: 15.99,
    image01: product_02_image_01,
    image02: product_02_image_02,
    category: "Instant",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "03",
    name: "Egg Pasta",
    price: 15.99,
    image01: product_03_image_01,
    image02: product_03_image_02,
    category: "Instant",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "04",
    name: "Egg",
    price: 1.99,
    image01: product_04_image_01,
    image02: product_04_image_02,
    category: "Dairy & Eggs",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "05",
    name: "Mango",
    price: 0.49,
    image01: product_05_image_01,
    image02: product_05_image_02,
    category: "Vegetables",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "06",
    name: "Mayonnaise",
    price: 4.99,
    image01: product_06_image_01,
    image02: product_06_image_02,
    category: "Burger",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "07",
    name: "Orange",
    price: 1.59,
    image01: product_07_image_01,
    image02: product_07_image_02,
    category: "Vegetables",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "08",
    name: "Watermelon",
    price: 9.99,
    image01: product_08_image_01,
    image02: product_08_image_02,
    category: "Vegetables",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "09",
    name: "White-carrot",
    price: 2.79,
    image01: product_09_image_01,
    image02: product_09_image_02,
    category: "Vegetables",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "10",
    name: "Apple",
    price: 1.49,
    image01: product_10_image_01,
    image02: product_10_image_02,
    category: "Vegetables",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "11",
    name: "Banana",
    price: 4.99,
    image01: product_11_image_01,
    image02: product_11_image_02,
    category: "Vegetables",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  },
  {
    id: "12",
    name: "Blueberry",
    price: 2.49,
    image01: product_12_image_01,
    image02: product_12_image_02,
    category: "Vegetables",

    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  }
];

export default products;