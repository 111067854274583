import React, { useState } from 'react'
import '../styles/OrderHistory.css'
import orders from '../data/orders'
import { Link } from 'react-router-dom'
import { GoArrowUpRight } from "react-icons/go";

export default function OrderHistory() {

  const [tab, setTab] = useState('All')

  const filteredProduct = orders.filter(item => {
    if (tab === "All") {
      return item
    }
    return item.status === tab
  })

  const statusColor = (status) => {
    switch (status) {
      case "Delivered":
        return "#EDCA6E";
      case "Collected":
        return "#212245";
      case "Completed":
        return "#53B175";
      case "Cancelled":
        return "#F57D82"
      default:
    }
  }

  return (
    <div className='order__history'>
      <div className='d-flex flex-column gap-3'>
        <h5>Order History</h5>

        <div className='tabs d-flex align-items-center gap-3'>
          <h6
            className={`${tab === 'All' ? 'tab__active' : ''}`}
            onClick={() => setTab('All')}>
            All
          </h6>
          <h6
            className={`${tab === 'Completed' ? 'tab__active' : ''}`}
            onClick={() => setTab('Completed')}>
            Completed
          </h6>
          <h6
            className={`${tab === 'Cancelled' ? 'tab__active' : ''}`}
            onClick={() => setTab('Cancelled')}>
            Cancelled
          </h6>
        </div>

        <div className='d-flex flex-column gap-3'>
          <div className='list__title'>
            <span>id</span>
            <span className='name'>Name</span>
            <div className='payment'>
              <span>Payment</span>
              <span className='total'>Total</span>
            </div>
            <span>Status</span>
          </div>
          {
            filteredProduct.map(item =>
              <div className='order__row'>
                <span>#{item.id}</span>
                <span className='name'>{item.name}</span>
                <div className='payment__detail'>
                  <span>{item.payment}</span>
                  <span>${item.total}</span>
                </div>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='d-flex align-items-center gap-2'>
                    <div className='status__dot' style={{ border: '1px solid ' + statusColor(item.status) }}>
                      <div className='status__inner__dot' style={{ backgroundColor: statusColor(item.status) }} />
                    </div>
                    <span style={{ color: statusColor(item.status) }}>{item.status}</span>
                  </div>
                  <Link to={'/orders/' + item.id}>
                      <GoArrowUpRight size={20} />
                  </Link>
                </div>
              </div>)
          }
        </div>
      </div>
    </div>
  )
}
